import React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { useExitEdit } from '@/modules/Bracket/hooks/useExitEdit';

export const ExitEditModeButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const { handleExitEdit } = useExitEdit();

  return (
    <>
      <Button
        bg={'#192E3B'}
        border='2px solid #7D8990'
        color={'white'}
        gap={'5px'}
        onClick={handleExitEdit}
        variant='outline'
        whiteSpace='break-spaces'
        lineHeight='0.84'
        {...props}
      >
        {t(tKeys.exitEditMode)}
      </Button>
    </>
  );
};

import { WithBannersLayout } from '@/components/Layout/with-banners';
import { WithoutBannersLayout } from '@/components/Layout/without-banners';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';
import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSettings } from '@/hooks/useSettings';
import { useJoinLeagueMutation } from '@/modules/LeagueManage/leagueManage.api';
import { useEffect } from 'react';

export const Layout = () => {
  const { isLgDesktop } = useSettings();
  useGameDate();

  const { value: leagueId } = useLocalStorage<string>(StorageKeysEnum.leagueId);
  const { jwt } = useJWT();
  const [joinLeague] = useJoinLeagueMutation();

  useEffect(() => {
    const joinLeagueIfNeeded = async () => {
      if (!!jwt && leagueId) {
        try {
          await joinLeague({
            league_id: Number(leagueId),
          }).unwrap();
        } catch (err) {}
      }
    };

    joinLeagueIfNeeded();
  }, [jwt, leagueId, joinLeague]);

  return isLgDesktop ? <WithBannersLayout /> : <WithoutBannersLayout />;
};

import { FC, PropsWithChildren } from 'react';

import { Flex } from '@chakra-ui/react';

export const AnswerButton: FC<
  PropsWithChildren & { isActive?: boolean; onClick: () => void }
> = ({ isActive, children, ...rest }) => {
  return (
    <Flex
      align='center'
      as='button'
      bg={isActive ? '#FFFFFF' : '#192E3B'}
      color={isActive ? '#192E3B' : '#FFFFFF'}
      fontWeight='800'
      h='26px'
      justify='center'
      w='26px'
      {...rest}
    >
      {children}
    </Flex>
  );
};

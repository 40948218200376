import { FC, useCallback, useMemo } from 'react';

import {
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
  NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
} from '@/core/env';
import { tKeys } from '@/i18n';

import { RightArrowButton } from '@/components/RightArrowButton';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import { selectBracket } from '@/modules/Bracket/bracket.slice';
import { CreateLeagueModal } from '@/modules/Leagues/containers/CreateLeagueModal';
import { PreregistrationBracketInfo } from '@/modules/Main/components/PreregistrationBracketInfo';

import { createPath } from '@/routes/types';

interface PreregistrationProps {
  onPreregister: () => void;
}

export const Preregistration: FC<PreregistrationProps> = ({
  onPreregister,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { jwt } = useJWT();
  const { user } = useSelector(selectBracket);
  const {
    isComing,
    isLoading,
    isOver,
    isLive,
    isSecondChanceComing,
    isSecondChanceLive,
    isSecondChanceOver,
  } = useGameDate();

  const { isMobile } = useSettings();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { withAuthCheck } = useAuthContext();

  const handleCreateLeague = useCallback(() => {
    withAuthCheck(() => onOpen());
  }, [onOpen, withAuthCheck]);

  const handlePlay = useCallback(
    () =>
      withAuthCheck(() => navigate(createPath({ path: RoutesEnum.brackets }))),
    [navigate, withAuthCheck],
  );
  const secondChanceLeagueId = user?.is_eligible
    ? ELIGIBLE_SECOND_CHANCE_LEAGUE_ID
    : NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID;

  const renderContent = useCallback(() => {
    if (isComing) {
      return !!jwt ? (
        <>
          <Text textAlign='center'>{t(tKeys.theOfficialNhlPlayoffSeeds)}</Text>
          <Flex align='center' direction='column' gap='16px' mt='24px' w='100%'>
            <Text fontSize='20px' fontWeight='700' textAlign='center'>
              {t(tKeys.inMeantimeJoinUp)}
            </Text>
            <Flex gap='8px' justify='center' w='100%'>
              <Button
                maxW='300px'
                onClick={() =>
                  navigate(
                    createPath({
                      path: RoutesEnum.leagues,
                    }),
                  )
                }
                variant='outline'
              >
                {t(tKeys.joinLeague)}
              </Button>
              <Button maxW='300px' onClick={handleCreateLeague}>
                {t(tKeys.createLeague)}
              </Button>
              <CreateLeagueModal isOpen={isOpen} onClose={onClose} />
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Text
            fontSize='24px'
            fontWeight='700'
            lineHeight='1.3'
            mb='8px'
            textAlign='center'
          >
            {t(tKeys.preregisterToday)}
          </Text>
          <Text textAlign='center'>{t(tKeys.theOfficialNhlPlayoffSeeds)}</Text>
          <RightArrowButton mt='24px' onClick={onPreregister}>
            {t(tKeys.preregister)}
          </RightArrowButton>
        </>
      );
    }
    if (isLive) {
      return (
        <>
          <Text
            fontSize='24px'
            fontWeight='700'
            lineHeight='1.3'
            mb='8px'
            textAlign='center'
          >
            {t(tKeys.submitYourBracketToday)}
          </Text>
          {/* <Text textAlign='center'>{t(tKeys.fillOutYourBrackets)}</Text> */}
          <RightArrowButton isDisabled={isOver} mt='24px' onClick={handlePlay}>
            {t(tKeys.playNow)}
          </RightArrowButton>
        </>
      );
    }
    if (isSecondChanceLive) {
      return (
        <>
          <Text
            fontSize='20px'
            fontWeight='700'
            lineHeight='1.3'
            mb='24px'
            textAlign='center'
          >
            {t(tKeys.welcomeBracketsLive)}
          </Text>
          <Text
            fontSize='20px'
            fontWeight='700'
            lineHeight='1.3'
            textAlign='center'
          >
            {t(tKeys.itsYourOpportunity)}
          </Text>
          <RightArrowButton
            mt='24px'
            onClick={() =>
              withAuthCheck(() =>
                navigate(createPath({ path: RoutesEnum.bracketsSecondChance })),
              )
            }
          >
            {t(tKeys.playNow)}
          </RightArrowButton>
        </>
      );
    }
    if (isSecondChanceOver) {
      return (
        <>
          <Text
            fontSize='24px'
            fontWeight='700'
            lineHeight='1.3'
            mb='8px'
            textAlign='center'
          >
            {t(tKeys.bracketsNowClosed)}
          </Text>
          <Text textAlign='center'>
            {t(tKeys.predictionsSetBracketsLocked)}
          </Text>
          <Flex
            alignItems='center'
            flexDirection={{ base: 'column', md: 'row' }}
            gap='24px'
            justify='center'
            mt='24px'
            w='100%'
          >
            <RightArrowButton
              maxW='300px'
              onClick={() =>
                withAuthCheck(() =>
                  navigate(createPath({ path: RoutesEnum.bracketsList })),
                )
              }
              w={{ base: 'fit-content', md: '100%' }}
            >
              {t(tKeys.viewBrackets)}
            </RightArrowButton>
            {user && (
              <RightArrowButton
                maxW='300px'
                onClick={() =>
                  withAuthCheck(() =>
                    navigate(
                      createPath({
                        path: RoutesEnum.leagueManage,
                        params: {
                          id: secondChanceLeagueId,
                        },
                      }),
                    ),
                  )
                }
              >
                {t(tKeys.viewSecondChance)}
              </RightArrowButton>
            )}
          </Flex>
        </>
      );
    }
    if (isOver || isSecondChanceComing) {
      return (
        <>
          <Text
            fontSize='24px'
            fontWeight='700'
            lineHeight='1.3'
            mb='8px'
            textAlign='center'
            textTransform='uppercase'
          >
            {t(tKeys.comingSoon)}!
          </Text>
          <Text textAlign='center'>{t(tKeys.secondChanceComingSoon)}</Text>
          <RightArrowButton
            mt='24px'
            onClick={() =>
              withAuthCheck(() =>
                navigate(createPath({ path: RoutesEnum.bracketsList })),
              )
            }
          >
            {t(tKeys.viewBrackets)}
          </RightArrowButton>
        </>
      );
    }
    return null;
  }, [
    isComing,
    isLive,
    isSecondChanceLive,
    isSecondChanceOver,
    isOver,
    isSecondChanceComing,
    jwt,
    t,
    handleCreateLeague,
    isOpen,
    onClose,
    onPreregister,
    navigate,
    handlePlay,
    withAuthCheck,
    user,
    secondChanceLeagueId,
  ]);

  const headingText = useMemo(() => {
    if (isOver || isSecondChanceOver || isSecondChanceComing)
      return t(tKeys.stanleyCup2024BC);
    return t(tKeys.predictNHLStanleyCup);
  }, [isOver, isSecondChanceComing, isSecondChanceOver, t]);

  return (
    <>
      {isLoading && <PageLoader />}

      <Flex
        align='center'
        bg='primaryDarkGradient'
        borderRadius='8px'
        direction='column'
        justify='center'
        mt='50px'
        p='40px 36px'
        position='relative'
        w='100%'
      >
        <PreregistrationBracketInfo />

        {!isSecondChanceLive && (
          <Heading
            as='h2'
            fontFamily='Antonio'
            fontSize='48px'
            fontWeight='700'
            mb='30px'
            textAlign='center'
            textTransform='uppercase'
          >
            {headingText}
          </Heading>
        )}

        {renderContent()}
      </Flex>
    </>
  );
};

const colors = {
  primaryBlue: '#214158',
  primaryDarkGradient:
    'linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)',
  primaryLightGradient: 'linear-gradient(163.37deg, #73ADD5 0%, #374F63 100%)',
  hoverLightGradient:
    'linear-gradient(141deg, rgba(21, 52, 73, 0.4), rgba(61, 97, 123, 0.4))',

  darkBlue: '#192E3B',
  lightBlue: '#5185AA',
  textDark: '#121212',

  modalOverlay: 'rgba(0, 0, 0, 0.80)',

  button: {
    primaryBg: '#FF8000',
    primaryColor: '#121212',
    primaryBorder: '#FFA850',
    primaryPressed: '#FF8000',
    outlineBg: '#192E3B',
    outlineColor: '#FFFFFF',
    outlineBorder: '#7D8990',
    outlineHover: '#233845',
    outlineActive: '#0F2431',
    successBg: '#0fb439',
    successBorder: '#21ff44',
    errorBg: '#E44242',
    errorBorder: '#DD052B',
  },

  inputBg: '#1E3647',

  ignisGrey: '#99ABB7',
  dionisBlue: '#29303C',
  tyrisBlue: '#18202E',
  bruxRed: '#E44242',
  gladGrey: '#7D8990',

  bruxBlue: 'rgba(33, 65, 88, 0.5)',

  sidebarGradient: 'linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)',
  tyrelGradient: 'linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)',
};

export default colors;

import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { nhlThinScrollbarStyles } from '@/utils/common.util';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const sizes = {
  md: definePartsStyle({
    dialog: {
      maxW: '600px',
    },
  }),
  full: definePartsStyle({
    dialogContainer: {
      px: '0',
      overflow: 'auto',
      bg: 'primaryDarkGradient',
    },
    dialog: {
      p: '0',
      bg: 'none',
    },
    header: {
      background: '#192E3B',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
};

const baseStyle = definePartsStyle({
  overlay: {
    bg: 'modalOverlay',
  },
  dialogContainer: {
    alignItems: 'center',
    px: '15px',
    overflow: 'auto',
    overflowX: 'hidden',
    // marginTop: '30px',,
  },
  dialog: {
    borderRadius: '16px',
    bg: 'primaryDarkGradient',
    p: '24px 16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
  },
  body: {
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
    ...nhlThinScrollbarStyles,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ModalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
});

export default ModalTheme;

import { FC } from 'react';

import { Box, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { TeamType } from '../../bracket.types';

import { StatisticRow } from './StatisticRow';

export const Analysis: FC<{ team1: TeamType; team2: TeamType }> = ({
  team1,
  team2,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      minHeight='590px'
      position='relative'
      templateColumns='1fr 1fr'
      w='100%'
    >
      <Box
        backgroundColor={`#${team1?.hex_code}`}
        borderRadius='0 0 0 4px'
        h='100%'
        py='16px'
      >
        <Flex
          align='center'
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent='flex-start'
        >
          <Image
            h={['50px', '90px']}
            mb={{ base: '6px', md: '0' }}
            src={team1.team_logo}
          />
          <Flex
            alignItems={{ base: 'center', md: 'unset' }}
            direction='column'
            mx={{ base: 0, md: 'auto' }}
          >
            <Text
              fontFamily='Antonio'
              fontSize={['12px', '20px']}
              fontWeight={600}
              textAlign='center'
            >
              {team1.city}
            </Text>
            <Text
              fontFamily='Antonio'
              fontSize={['14px', '26px']}
              fontWeight={600}
              textAlign='center'
              textTransform='uppercase'
            >
              {team1.nickname}
            </Text>
            <Text
              fontFamily='Antonio'
              fontSize={['10px', '14px']}
              fontWeight={600}
              textAlign='center'
            >
              {team1.season_standings}
            </Text>
          </Flex>
        </Flex>
      </Box>

      <Box
        backgroundColor={`#${team2?.hex_code}`}
        borderRadius='0 0 4px 0'
        h='100%'
        py='16px'
      >
        <Flex
          align='center'
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          justify='flex-end'
        >
          <Flex
            align='end'
            alignItems={{ base: 'center', md: 'unset' }}
            direction='column'
            mx={{ base: 0, md: 'auto' }}
          >
            <Text
              fontFamily='Antonio'
              fontSize={['12px', '20px']}
              fontWeight={600}
              textAlign='center'
            >
              {team2.city}
            </Text>
            <Text
              fontFamily='Antonio'
              fontSize={['14px', '26px']}
              fontWeight={600}
              textAlign='center'
              textTransform='uppercase'
            >
              {team2.nickname}
            </Text>
            <Text
              fontFamily='Averta'
              fontSize={['10px', '14px']}
              fontWeight={600}
              textAlign='center'
            >
              {team2.season_standings}
            </Text>
          </Flex>
          <Image
            h={['50px', '90px']}
            mb={{ base: '6px', md: '0' }}
            src={team2.team_logo}
          />
        </Flex>
      </Box>

      <Flex
        alignItems='center'
        backgroundColor='#FFFFFF'
        borderRadius='100%'
        color='#121212'
        fontSize='16px'
        fontWeight='800'
        h='40px'
        justifyContent='center'
        left='50%'
        position='absolute'
        textTransform='uppercase'
        top='35px'
        transform='translateX(-50%)'
        w='40px'
      >
        {t(tKeys.vs)}
      </Flex>

      <Grid
        bgColor='#162733'
        borderRadius='4px'
        gap='10px'
        left='50%'
        maxW={{ base: '320px', md: '652px' }}
        p={['8px', '20px']}
        position='absolute'
        top={{ base: '145px', md: '120px' }}
        transform='translateX(-50%)'
        w='100%'
      >
        <StatisticRow
          property={'points'}
          title={t(tKeys.seasonPoints)}
          shouldRound={true}
          {...{ team1, team2 }}
        />

        <StatisticRow
          property={'goals_per_game'}
          title={t(tKeys.goalsPerGame)}
          shouldRoundUp={true}
          {...{ team1, team2 }}
        />
        <StatisticRow
          property={'goals_against_per_game'}
          title={t(tKeys.goalsAgainstPerGame)}
          shouldRoundUp={true}
          {...{ team1, team2 }}
        />
        <StatisticRow
          property={'power_play_pct'}
          title={t(tKeys.powerPlayPct)}
          shouldRound={true}
          {...{ team1, team2 }}
        />
        <StatisticRow
          property={'penalty_kill_pct'}
          title={t(tKeys.penaltyKillPct)}
          shouldRound={true}
          {...{ team1, team2 }}
        />

        <Grid gridTemplateColumns='1fr 2fr 1fr' mt={'0px'}>
          <Flex align='center' direction='column'>
            <Image
              bg='#1a2d3a'
              border='2px solid #1a2d3a'
              borderRadius='50%'
              h='48px'
              src={team1.leading_scorer_avatar}
              w='48px'
            />
            {/* <Text
              fontSize={['12px', '20px']}
              fontWeight='600'
              textAlign='center'
            >
              {team1.leading_scorer}
            </Text> */}
            <Text
              fontSize={['12px', '20px']}
              fontWeight='600'
              textAlign='center'
            >
              {team1.leading_scorer &&
                team1.leading_scorer.split(' ').map((word, index) => (
                  <>
                    {word}
                    {index < team1.leading_scorer.split(' ').length - 1 && (
                      <br />
                    )}
                  </>
                ))}
            </Text>
            <Text
              fontSize={['12px', '20px']}
              fontWeight='600'
              textAlign='center'
            >
              {team1.leading_scorer_points}
            </Text>
          </Flex>
          <GridItem
            alignItems='center'
            display='flex'
            justifyContent='center'
            textAlign='center'
          >
            {t(tKeys.leadingScorer)}
          </GridItem>
          <Flex align='center' direction='column'>
            <Image
              bg='#1a2d3a'
              border='2px solid #1a2d3a'
              borderRadius='50%'
              h='48px'
              src={team2.leading_scorer_avatar}
              w='48px'
            />
            {/* <Text
              fontSize={['12px', '20px']}
              fontWeight='600'
              textAlign='center'
            >
              {team2.leading_scorer}
            </Text> */}

            <Text
              fontSize={['12px', '20px']}
              fontWeight='600'
              textAlign='center'
            >
              {team2.leading_scorer &&
                team2.leading_scorer.split(' ').map((word, index) => (
                  <>
                    {word}
                    {index < team2.leading_scorer.split(' ').length - 1 && (
                      <br />
                    )}
                  </>
                ))}
            </Text>
            <Text
              fontSize={['12px', '20px']}
              fontWeight='600'
              textAlign='center'
            >
              {team2.leading_scorer_points}
            </Text>
          </Flex>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { FC, forwardRef, PropsWithChildren, useMemo } from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { tKeys } from '@/i18n';

import cupImage from '@/assets/images/cup.png';
import SecondChanceCup from '@/assets/images/second-chance-cup.svg';

import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useGameDate } from '@/hooks/useGameDate';
import { useLang } from '@/hooks/useLang';
import { useSettings } from '@/hooks/useSettings';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import {
  CLOSE_BONUS_MODAL,
  selectBracket,
  TOGGLE_FINAL_BONUS_MODAL,
} from '../bracket.slice';
import { UI_SETTINGS } from '../helpers/constants';
import {
  getEntryCorrectPct,
  getMatchesByRounds,
  getSecondChanceEntryCorrectPct,
} from '../helpers/getMatchesByRounds';
import { useBracketSettings } from '../hooks/useBracketSettings';

import { BonusModal } from './BonusModal';
import { ChampionLogo } from './ChampionLogo';
import { FinalBonusModal } from './FinalBonusModal';
import { Match } from './Match';

export const Bracket = forwardRef<HTMLDivElement>((props, ref) => {
  const { seriesResults, isBonusModalVisible, finalModal, entry, entrySC } =
    useSelector(selectBracket);
  const { isPreRegistrationPage } = useBracketSettings();
  const { pathname } = useLocation();
  const { isOver, isSecondChanceOver } = useGameDate();
  const { isMobile } = useSettings();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  // const { lang } = useLang();

  // const isFrench = lang === 'fr';

  const bracketViewType = searchParams.get(SearchParamsEnum.viewType) as
    | BracketMode
    | undefined;

  const isSecondChancePage = useMemo(
    () => pathname === bracketsSecondChancePath(),
    [pathname],
  );

  // Need to prevent to show existing filled bracket if contest isn't started yet
  const bracketWithOnlyFirstRound = seriesResults.map((match) => {
    if (match.id < 199) {
      return match;
    }

    return {
      ...match,
      team_1_id: null,
      team_2_id: null,
    };
  });

  const bracketToShow = isPreRegistrationPage
    ? bracketWithOnlyFirstRound
    : seriesResults;

  const {
    roundOneWest,
    roundOneEast,
    roundTwoWest,
    roundTwoEast,
    roundThreeWest,
    roundThreeEast,
    final,
  } = getMatchesByRounds(bracketToShow);

  const isBracketOver = useMemo(
    () => (isSecondChancePage ? isSecondChanceOver : isOver),
    [isOver, isSecondChanceOver, isSecondChancePage],
  );

  const paddingMobile = () => {
    if (pathname === bracketsSecondChancePath() && bracketViewType)
      return '55px 10px 120px';
    else if (pathname === bracketsSecondChancePath() && !bracketViewType)
      return '160px 10px 120px';
    return '10px 10px 120px';
  };

  const logoTop = () => {
    if (isMobile) {
      if (isSecondChancePage && bracketViewType) return '35px';
      if (!isSecondChancePage) return '35px';
      return '135px';
    } else return '35px';
  };

  return (
    <>
      <>
        <Flex
          align='center'
          gap='20px'
          overflow='auto'
          p={[
            paddingMobile(),
            pathname === bracketsSecondChancePath()
              ? '125px 20px 20px'
              : '20px',
          ]}
          position='relative'
          ref={ref}
        >
          {pathname !== bracketsSecondChancePath() && (
            <Flex
              direction='column'
              flex={1}
              gap='10px'
              minW={UI_SETTINGS.COL_MIN_W}
            >
              <Block>{t(tKeys.firstRound)}</Block>
              {roundOneWest.map((round) => (
                <Match key={round.id} {...round} />
              ))}
              <Block />
            </Flex>
          )}

          <Flex
            direction='column'
            flex={1}
            gap='10px'
            minW={UI_SETTINGS.COL_MIN_W}
          >
            <Block>{t(tKeys.secondRound)}</Block>
            {roundTwoWest.map((round) => (
              <Match key={round.id} {...round} />
            ))}
            <Block />
          </Flex>

          <Flex
            direction='column'
            flex={1}
            gap='10px'
            minW={UI_SETTINGS.COL_MIN_W}
          >
            {isBracketOver && (
              <Box position='absolute' top='25px' width='100px'>
                <Text
                  color='#99ABB7'
                  fontFamily='AvertaStd, sans-serif'
                  fontSize='16px'
                  fontWeight='bold'
                  textAlign='center'
                >
                  {t(tKeys.totalPoints)}
                </Text>
                <Text
                  fontFamily='"Antonio", sans-serif'
                  fontSize='24px'
                  fontWeight={700}
                  textAlign='center'
                >
                  {isSecondChancePage ? entrySC?.points : entry.points} /{' '}
                  {isSecondChancePage
                    ? entrySC?.possible_points
                    : entry.possible_points}
                </Text>
              </Box>
            )}
            <Block>{t(tKeys.conferenceFinal)}</Block>
            <Match {...roundThreeWest[0]} />
            <Block />
          </Flex>

          <Flex
            align='center'
            direction='column'
            flex={isSecondChancePage ? 1.5 : 2}
            gap='10px'
            minW={UI_SETTINGS.COL_MIN_W_FINAL}
          >
            {(!isPreRegistrationPage || isMobile) && (
              <Box
                id='bracket-name'
                position='absolute'
                top={logoTop()}
                width={isSecondChancePage && isMobile ? '155px' : '185px'}
              >
                <ChampionLogo
                  minHeight='auto'
                  mx='auto'
                  teamId={
                    isSecondChancePage
                      ? Number(entrySC?.match_401_pick)
                      : Number(entry.match_401_pick)
                  }
                  width='inherit'
                />
              </Box>
            )}

            <Block>
              {t(tKeys.stanleyCupFinal)}
              {/* {isFrench ? <span>&#127339;</span> : <span>&#8482;</span>} */}
            </Block>
            <Match {...final} />
            <Block />

            {(isSecondChancePage ? entrySC.tiebreaker : entry.tiebreaker) && (
              <Box
                bottom={
                  isSecondChancePage
                    ? isMobile
                      ? '225px'
                      : '125px'
                    : isMobile
                      ? '300px'
                      : '220px'
                }
                position='absolute'
                width='100px'
              >
                <Text
                  color='#99ABB7'
                  fontFamily='AvertaStd, sans-serif'
                  fontSize='16px'
                  fontWeight='bold'
                  textAlign='center'
                >
                  {t(tKeys.tieBreaker)}:
                </Text>
                <Text
                  fontFamily='"Antonio", sans-serif'
                  fontSize='24px'
                  fontWeight={700}
                  textAlign='center'
                >
                  {isSecondChancePage ? entrySC.tiebreaker : entry.tiebreaker}
                </Text>
              </Box>
            )}

            {isMobile && (
              <Flex
                bottom='100px'
                justify='center'
                position='absolute'
                w='100%'
              >
                <Image
                  height='80px'
                  src={isSecondChancePage ? SecondChanceCup : cupImage}
                />
              </Flex>
            )}
          </Flex>

          <Flex
            direction='column'
            flex={1}
            gap='10px'
            minW={UI_SETTINGS.COL_MIN_W}
          >
            {isBracketOver && (
              <Box position='absolute' top='25px' width='100px'>
                <Text
                  color='#99ABB7'
                  fontFamily='AvertaStd, sans-serif'
                  fontSize='16px'
                  fontWeight='bold'
                  textAlign='center'
                >
                  {t(tKeys.correct)}
                </Text>
                <Text
                  fontFamily='"Antonio", sans-serif'
                  fontSize='24px'
                  fontWeight={700}
                  textAlign='center'
                >
                  {isSecondChancePage
                    ? getSecondChanceEntryCorrectPct(entrySC, seriesResults)
                    : getEntryCorrectPct(entry, seriesResults)}
                  %
                </Text>
              </Box>
            )}
            <Block>{t(tKeys.conferenceFinal)}</Block>
            <Match {...roundThreeEast[0]} />
            <Block />
          </Flex>
          <Flex
            direction='column'
            flex={1}
            gap='10px'
            minW={UI_SETTINGS.COL_MIN_W}
          >
            <Block>{t(tKeys.secondRound)}</Block>
            {roundTwoEast.map((round) => (
              <Match key={round.id} {...round} />
            ))}
            <Block />
          </Flex>

          {pathname !== bracketsSecondChancePath() && (
            <Flex
              direction='column'
              flex={1}
              gap='10px'
              id='round-second-east'
              minW={UI_SETTINGS.COL_MIN_W}
            >
              <Block>{t(tKeys.firstRound)}</Block>
              {roundOneEast.map((round) => (
                <Match key={round.id} {...round} />
              ))}
              <Block />
            </Flex>
          )}
        </Flex>
      </>

      <FinalBonusModal
        isOpen={finalModal.isOpen}
        onClose={() => dispatch(TOGGLE_FINAL_BONUS_MODAL(false))}
      />

      <BonusModal
        isOpen={isBonusModalVisible}
        onClose={() => dispatch(CLOSE_BONUS_MODAL())}
      />
    </>
  );
});
Bracket.displayName = 'Bracket';

// Need for center bracket
const Block: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex align='center' h='30px' justify='center' w='100%'>
      <Text
        fontFamily='AvertaStd, sans-serif'
        fontSize='12px'
        fontWeight={700}
        maxW='112px'
        textAlign='center'
        textTransform={'uppercase'}
      >
        {children}
      </Text>
    </Flex>
  );
};

import React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { useSubmitButton } from '../../../hooks/useSubmitButton';

export const SubmitButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const { isLoading, isSubmitDisabled, handleSubmit } = useSubmitButton();

  return (
    <>
      <Button
        isDisabled={isSubmitDisabled}
        isLoading={isLoading}
        onClick={handleSubmit}
        whiteSpace='break-spaces'
        lineHeight='0.84'
        {...props}
      >
        {t(tKeys.saveBracket)}
      </Button>
    </>
  );
};

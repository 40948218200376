import React, { useEffect } from 'react';

import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { useGameTimer } from '@/hooks/useGameTimer';

export const PreregistrationBracketInfo = () => {
  const { t } = useTranslation();

  const {
    timeLeft,
    isComing,
    isSecondChanceComing,
    isOver,
    secondChanceStartDate,
    isSecondChanceOver,
    isSecondChanceLive,
  } = useGameTimer();

  const renderText = () => {
    if (isSecondChanceComing) return t(tKeys.secondChanceOpens);
    if (isSecondChanceLive) return t(tKeys.secondChanceLock);
    if (!isSecondChanceComing && isOver) return t(tKeys.bracketsLock);
    if (isComing) return t(tKeys.bracketsOpen);
    return t(tKeys.bracketsLock);
  };

  if (
    (isOver && !secondChanceStartDate) ||
    (isOver && isSecondChanceComing) ||
    isSecondChanceOver
  )
    return null;
  return (
    <Flex
      align='center'
      bg='primaryDarkGradient'
      boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.55)'
      fontSize='16px'
      justify='center'
      textAlign='center'
      minWidth='325px'
      // pl={4}
      p={2}
      position='absolute'
      // pr={4}
      textTransform='uppercase'
      top='0'
      transform='translateY(-50%)'
    >
      {`${renderText()}: ${timeLeft || t(tKeys.comingSoon)}`}
    </Flex>
  );
};

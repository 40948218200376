import { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { TeamType } from '../../bracket.types';

import { CompareLine } from './CompareLine';

export const StatisticRow: FC<{
  title: string;
  team1: TeamType;
  team2: TeamType;
  property: keyof TeamType;
  shouldRound?: boolean;
  shouldRoundUp?: boolean;
}> = ({ title, property, team1, team2, shouldRound, shouldRoundUp }) => {
  const value1 = Number(team1[property]);
  const value2 = Number(team2[property]);

  const color1 = `#${team1.hex_code}`;
  const color2 = `#${team2.hex_code}`;

  const percent1 = (value1 / (value1 + value2)) * 100;
  const percent2 = (value2 / (value1 + value2)) * 100;

  const roundUpToTenth = (num: number) => Math.ceil(num * 10) / 10;

  const roundedValue1 =
    property === 'points'
      ? Math.round(value1).toString()
      : shouldRound
        ? (Math.round(value1 * 10) / 10).toFixed(1)
        : value1.toString();

  const roundedValue2 =
    property === 'points'
      ? Math.round(value2).toString()
      : shouldRound
        ? (Math.round(value2 * 10) / 10).toFixed(1)
        : value2.toString();

  const roundedUpValue1 =
    property === 'points'
      ? roundedValue1
      : shouldRoundUp
        ? roundUpToTenth(value1).toFixed(2)
        : roundedValue1;

  const roundedUpValue2 =
    property === 'points'
      ? roundedValue2
      : shouldRoundUp
        ? roundUpToTenth(value2).toFixed(2)
        : roundedValue2;

  return (
    <Box>
      <Text fontWeight='bold' textAlign='center'>
        {title}
      </Text>
      <Flex align='center' gap={'10px'} justify='space-between'>
        <Text
          fontSize='16px'
          fontWeight='800'
          minW={{ base: '40px', md: '60px' }}
          textAlign='right'
        >
          {roundedUpValue1}
        </Text>
        <CompareLine {...{ color1, color2, percent1, percent2 }} />
        <Text
          fontSize='16px'
          fontWeight='800'
          minW={{ base: '40px', md: '60px' }}
          textAlign='left'
        >
          {roundedUpValue2}
        </Text>
      </Flex>
    </Box>
  );
};

import React, { useCallback, useMemo, useState } from 'react';

import { Button, Image, useDisclosure, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import editIcon from '@/assets/icons/edit.svg';
import hierarchyDark from '@/assets/icons/hierarchy-dark.svg';
import joinedIcon from '@/assets/icons/joined.svg';
import roundedPlusIcon from '@/assets/icons/rounded-plus.svg';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';
import { useToast } from '@/hooks/useToast';

import { useAddEntryToLeagueMutation } from '@/modules/Bracket/bracket.api';
import { selectBracket } from '@/modules/Bracket/bracket.slice';
import { AssignBracketModal } from '@/modules/Leagues/containers/AssignBracketModal';
import { League } from '@/modules/Leagues/leagues.types';

import leaguesApi from '../leagues.api';
import { useNavigate } from 'react-router-dom';
import { createPath } from '@/routes/types';
import {
  ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
  NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
} from '@/core/env';
import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';
import { useJWT } from '@/hooks/useJWT';
import { useLocalStorage } from '@/hooks/useLocalStorage';

type Props = {
  league: League;
  onNavigateToLeagueManage: (leagueId: string) => void;
  isAssignLeague?: boolean;
};

export const LeagueEntryRender: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { league, onNavigateToLeagueManage, isAssignLeague } = props;
  const dispatch = useDispatch();

  const { assignEntryId } = useSelector(selectBracket);
  const toast = useToast();
  const { setItem } = useLocalStorage<string>(StorageKeysEnum.leagueId);
  const { t } = useTranslation();
  const { jwt } = useJWT();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { withAuthCheck } = useAuthContext();
  const { isComing, isOver, isSecondChanceOver } = useGameDate();
  const { isMobile } = useSettings();

  const defaultSelectedValue = useMemo(
    () => league?.entry?.entry_id === assignEntryId,
    [assignEntryId, league?.entry?.entry_id],
  );

  const [isSelected, setSelected] = useState(defaultSelectedValue);

  const [addEntryToLeague, { isLoading }] = useAddEntryToLeagueMutation();

  const handleLeagueClick = useCallback(
    (league_id: number | string) => {
      withAuthCheck(() => onNavigateToLeagueManage(String(league_id)));
    },
    [onNavigateToLeagueManage, withAuthCheck],
  );

  const saveLeagueIdJwt = (jwt: string, leagueId: string) => {
    if (!jwt && leagueId) {
      setItem(String(leagueId));
    }
  };

  const handleAssignBracketClick = useCallback(() => {
    withAuthCheck(() => onOpen());
  }, [onOpen, withAuthCheck]);

  const handleAddEntryToLeague = useCallback(async () => {
    try {
      await addEntryToLeague({
        entryId: assignEntryId || '',
        leagueId: league?.league_id,
      }).unwrap();

      setSelected(true);
      dispatch(
        leaguesApi.util.invalidateTags([
          ApiTagsEnum.GET_FILTERED_LEAGUES,
          ApiTagsEnum.GET_ENTRIES,
        ]),
      );

      toast(t(tKeys.successAssignBracket), 'success');
    } catch (error) {
      throw new Error(error?.data?.message || error?.message);
    }
  }, [addEntryToLeague, assignEntryId, league?.league_id]);

  const renderer = useCallback(() => {
    if (isComing && !league.is_member) {
      return (
        <Button
          borderRadius='60px'
          fontSize={14}
          onClick={() => {
            handleLeagueClick(league.league_id);
          }}
          paddingBottom={2}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={2}
          rightIcon={<Image src={roundedPlusIcon} />}
          size='sm'
          sx={{ margin: '0 auto' }}
          variant='outline'
          width={isMobile ? '100%' : 'auto'}
        >
          {t(tKeys.join)}
        </Button>
      );
    }
    if (isComing && league.is_member) {
      return (
        <Button
          alignItems='center'
          borderRadius='60px'
          fontSize={14}
          onClick={() => handleLeagueClick(league.league_id)}
          paddingBottom={2}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={2}
          rightIcon={<Image src={joinedIcon} />}
          size='sm'
          sx={{ margin: '0 auto' }}
          variant='success'
          width={isMobile ? '100%' : 'auto'}
        >
          {t(tKeys.joined)}
        </Button>
      );
    }
    if (
      league.entry &&
      [
        ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
        NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
      ].includes(league?.league_id.toString())
    ) {
      return (
        <Button
          alignItems='center'
          borderRadius='60px'
          fontSize={14}
          isDisabled={isSecondChanceOver}
          onClick={() => handleLeagueClick(league.league_id)}
          paddingBottom={2}
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          rightIcon={<Image src={editIcon} />}
          size='sm'
          sx={{ margin: '0 auto' }}
          variant='success'
          width={isMobile ? '100%' : 'auto'}
        >
          <Text overflow='hidden' textOverflow='ellipsis'>
            {league.entry ? league.entry.entry_name : 'Create Bracket'}
          </Text>
        </Button>
      );
    }
    if (league.entry) {
      return (
        <Button
          alignItems='center'
          borderRadius='60px'
          fontSize={14}
          isDisabled={isOver}
          onClick={() => handleLeagueClick(league.league_id)}
          paddingBottom={2}
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          rightIcon={<Image src={editIcon} />}
          size='sm'
          sx={{ margin: '0 auto' }}
          variant='success'
          width={isMobile ? '100%' : 'auto'}
        >
          <Text overflow='hidden' textOverflow='ellipsis'>
            {league.entry.entry_name}
          </Text>
        </Button>
      );
    }

    if (league.entry === null && isAssignLeague) {
      return (
        <Button
          alignItems='center'
          bgColor={isSelected ? '#1E3543' : ''}
          border={isSelected ? 'none' : ''}
          borderRadius='60px'
          fontSize={14}
          isDisabled={isOver}
          isLoading={isLoading}
          onClick={handleAddEntryToLeague}
          paddingBottom={2}
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          size='sm'
          sx={{ margin: '0 auto' }}
          variant='success'
          width={isMobile ? '100%' : 'auto'}
        >
          <Text overflow='hidden' textOverflow='ellipsis'>
            {t(isSelected ? tKeys.selected : tKeys.select)}
          </Text>
        </Button>
      );
    }
    if (
      league?.entry === null &&
      league?.is_member &&
      [
        ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
        NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
      ].includes(league?.league_id.toString())
    ) {
      return (
        <Button
          borderRadius='60px'
          fontSize={14}
          isDisabled={isSecondChanceOver}
          onClick={() =>
            navigate(createPath({ path: RoutesEnum.bracketsSecondChance }))
          }
          paddingBottom={2}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={2}
          rightIcon={<Image height='14px' src={hierarchyDark} width='14px' />}
          size='sm'
          sx={{ margin: '0 auto' }}
          width={'auto'}
        >
          {t(tKeys.createNewBracket)}
        </Button>
      );
    }

    if (league.entry === null && league.is_member) {
      return (
        <>
          <Button
            borderRadius='60px'
            fontSize={14}
            isDisabled={isOver}
            onClick={handleAssignBracketClick}
            paddingBottom={2}
            paddingLeft={6}
            paddingRight={6}
            paddingTop={2}
            rightIcon={<Image height='14px' src={hierarchyDark} width='14px' />}
            size='sm'
            sx={{ margin: '0 auto' }}
            width={isMobile ? '100%' : 'auto'}
          >
            {t(tKeys.assignBracket)}
          </Button>
          <AssignBracketModal
            isOpen={isOpen}
            leagueEntry={league?.entry}
            leagueId={league.league_id}
            onClose={onClose}
          />
        </>
      );
    }

    return (
      <Button
        borderRadius='60px'
        fontSize={14}
        isDisabled={isOver}
        onClick={() => {
          saveLeagueIdJwt(jwt, league.league_id);
          handleLeagueClick(league.league_id);
        }}
        paddingBottom={2}
        paddingLeft={6}
        paddingRight={6}
        paddingTop={2}
        rightIcon={<Image src={roundedPlusIcon} />}
        size='sm'
        sx={{ margin: '0 auto' }}
        variant='outline'
        width={isMobile ? '100%' : 'auto'}
      >
        {t(tKeys.join)}
      </Button>
    );
  }, [
    isComing,
    league.is_member,
    league.entry,
    league.league_id,
    isAssignLeague,
    isOver,
    isMobile,
    t,
    handleLeagueClick,
    isSelected,
    isLoading,
    handleAddEntryToLeague,
    handleAssignBracketClick,
    isOpen,
    onClose,
  ]);

  return renderer();
};
